import imageLoader from '../helpers/image_loader';

export default (src, container, defaultImage = null) => {
  const imageContainer = document.querySelector(container);
  const fullImageContainer = imageContainer.querySelector('.full-image') || imageContainer;
  return imageLoader(src)
    .then((image) => {
      fullImageContainer.style.backgroundImage = `url(${image.src})`;
    })
    .catch(() => {
      imageLoader(defaultImage).then((image) => {
        fullImageContainer.style.backgroundImage = `url(${image.src})`;
        fullImageContainer.classList.add('default-image');
      });
    });
};
