import '../../sass/base.scss';
import '../../sass/components/fonts/quicksand.scss';
import '../../sass/components/fonts/spareroom_sans.scss';
import '../../sass/pages/flag_page.scss';
import '../../sass/components/button.scss';
import Cookies from 'js-cookie';
import progressiveLoader from '../components/progressive_image_loader';
import get from '../helpers/data_layer/get';
import cookie_consent from '../apps/cookie_consent';

const country = get('site.country');
const features = get('config.features');
if (features.home_baked_cookie_consent) {
  cookie_consent.init({ opt_out: country == 'US' });
}

const staticFilesServer = get('site.static_files_server');

document.addEventListener('DOMContentLoaded', () =>
  progressiveLoader(
    `${staticFilesServer}/img/spareroom/landingpages/heroes/smiling_people_hero.jpg`,
    '.flag-page__hero',
  ),
);

// Upon selection of a given country, a cookie is created to store that choice for 30 days
// so the user will bypass the flag page straight to that country's domain when returning
// subsequently. (Returning to the site also renews the cookie for another 30 days.)

const siteChoiceUSA = document.getElementById('site-choice-USA');
const siteChoiceUK = document.getElementById('site-choice-UK');

siteChoiceUSA.addEventListener('click', () =>
  Cookies.set('country_id', '2', { domain: 'spareroom.com', expires: 30 }),
);
siteChoiceUK.addEventListener('click', () =>
  Cookies.set('country_id', '1', { domain: 'spareroom.com', expires: 30 }),
);
