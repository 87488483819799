interface EventTrackingArguments {
  category: string;
  action: string;
  label?: string;
  value?: number;
  non_interaction?: boolean;
}

interface Event {
  event: string;
  ga_event_category: string;
  ga_event_action: string;
  ga_event_label?: string;
  ga_event_value?: number;
  ga_event_non_interaction?: boolean;
}

declare global {
  interface Window {
    dataLayer: any;
  }
}

export default function trackEvent({
  category,
  action,
  label,
  value,
  non_interaction = false,
}: EventTrackingArguments) {
  if (window.dataLayer && window.dataLayer.push) {
    const event = <Event>{
      event: 'ga.event',
      ga_event_category: category,
      ga_event_action: action,
      ga_event_label: label,
      ga_event_value: value,
      ga_event_non_interaction: non_interaction,
    };
    return window.dataLayer.push(event);
  }
  return false;
}
