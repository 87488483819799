const consent_map = {
  ad_storage: 'targeting',
  ad_user_data: 'targeting',
  ad_personalization: 'targeting',
  analytics_storage: 'performance',
  functionality_storage: 'functionality',
  personalization_storage: 'functionality',
  security_storage: 'performance',
};

function enable_gtag() {
  if (window.gtag && typeof window.gtag === 'function') {
    return true;
  }
  function gtag(...args) {
    dataLayer.push(...args);
  }
  window.gtag = gtag;
  return true;
}

function get_stored_consent() {
  const stored_consent = localStorage.getItem('user_consent_settings');
  if (stored_consent) {
    const parsed_consent = JSON.parse(stored_consent);
    if (typeof parsed_consent === 'object' && parsed_consent.ad_storage) {
      return parsed_consent;
    }
  }
  return {};
}

function stored_consent_is_not_default() {
  const {
    ad_storage,
    ad_user_data,
    ad_personalization,
    analytics_storage,
    functionality_storage,
    personalization_storage,
    security_storage,
  } = get_stored_consent();
  if (
    (ad_storage == 'granted' &&
      ad_user_data == 'granted' &&
      ad_personalization == 'granted') ||
    (analytics_storage == 'granted' && security_storage == 'granted')
  ) {
    return true;
  }
  return false;
}

function accept_all() {
  const { dataLayer } = window;
  const consent_to_all = {
    ad_storage: 'granted',
    ad_user_data: 'granted',
    ad_personalization: 'granted',
    analytics_storage: 'granted',
    functionality_storage: 'granted',
    personalization_storage: 'granted',
    security_storage: 'granted',
  };
  if (dataLayer && dataLayer.push) {
    gtag('consent', 'update', consent_to_all);
  }
  localStorage.setItem('user_consent_settings', JSON.stringify(consent_to_all));
}

function update_consent(selected_consent) {
  const { dataLayer } = window;
  const consent = {};
  const consent_values = Object.values(selected_consent);
  if (consent_values.every(Boolean)) {
    // all selected
    return accept_all();
  }
  Object.entries(consent_map).forEach(([key, value]) => {
    consent[key] = selected_consent[value] ? 'granted' : 'denied';
  });
  if (dataLayer && dataLayer.push) {
    gtag('consent', 'update', consent);
  }
  return localStorage.setItem('user_consent_settings', JSON.stringify(consent));
}

export default {
  accept_all,
  get_stored_consent,
  update_consent,
  enable_gtag,
  stored_consent_is_not_default,
};
